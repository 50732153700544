<template>
  <v-card>
    <v-card-title class="d-flex align-center">
      <v-btn icon @click="$router.go(-1)" class="mr-4">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <div>
        {{ consent.consent_requests ? consent.consent_requests[0].purposes[0]:"" }}
      </div>
      <v-spacer />
      <div>
        <v-btn outlined color="primary" @click="openEditDialog()">
          Editar Consentimento
          <v-icon class="ml-2">mdi-pencil</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-divider />
    <v-card-text class="grey--text text--darken-4">
      <ConsentDataPanel title="Titular dos Dados" height="100px" class="my-2">
        <div class="font-weight-medium text-h6">
          {{ bearer.name }}
        </div>
        <div> {{ bearer.cpf }}</div>
        <div> {{ bearer.email }}</div>
        <div> {{ bearer.phone }}</div>
      </ConsentDataPanel>
      <v-expand-transition>
        <div v-if="withNewBearer && currentAnswer == undefined">
          <div class="d-flex">
            <v-sheet width="500px" class="pa-4 rounded warning lighten-3 elevation-1 d-flex align-center">
              <v-icon large left>mdi-alert</v-icon>
              <div class="text-center">
                <div class="text-h6">
                  Atenção!
                </div>
                <div class="text-subtitle-2">
                  O Titular ainda não se cadastrou no aplicativo
                </div>
              </div>
            </v-sheet>
            <div class="pl-4 py-1 text-body-2 d-flex flex-column justify-space-between">
              <p>
                Oriente o titular dos dados a instalar o aplicativo de celular e cadastrar-se usando seu CPF.
                As ações de Aceitar, Recusar ou Revogar consentimentos são feitas <b>somente pelo aplicativo</b>.
              </p>
              <p class="mb-0">
                Preparamos uma mensagem que você pode enviar para o titular:
              </p>
            </div>
          </div>
          <div class="d-flex mt-2">
            <v-textarea
              ref="textToCopy"
              :value="textToSend"
              readonly filled rows="3" hide-details no-resize
              class="mb-3"
            />
            <v-sheet class="pl-2 d-flex flex-column align-space-around">
              <v-btn color="grey darken-3" dark small class="py-6" @click="copyText()">
                <v-icon small left>mdi-content-copy</v-icon>
                Copiar <br/> texto
              </v-btn>
              <v-btn
                color="green darken-3" dark small class="py-6 px-6 mt-2"
                :href="`https://wa.me/55${consent.new_bearer.cpf.match(/\d+/g).join('')}?text=${textToSend}`"
              >
                <v-icon left>mdi-whatsapp</v-icon>
                Enviar por <br/> WhatsApp
              </v-btn>
            </v-sheet>
          </div>
        </div>
      </v-expand-transition>

      <template v-if="consent.consent_requests">
        <ConsentRequestContent
          v-for="consent_request in (showingOld ? consent.consent_requests : [consent.consent_requests[0]])"
          :key="consent_request.id"
          :consent_request="consent_request"
        />
        <template v-if="consent.consent_requests.length > 1">
          <v-btn color="info" large block @click="showingOld = !showingOld">
            {{ showingOld ? "Esconder":"Mostrar" }} Soliticações de Consentimento Antigas
          </v-btn>
        </template>
      </template>
    </v-card-text>

    <v-dialog
      v-model="editDialog.active"
      max-width="1000"
      :persistent="editDialog.loading"
    >
      <v-card class="pa-2">
        <v-card-title class="d-flex align-center justify-space-between pb-0">
          <div>Edição da Solicitação de Consentimento</div>
          <div>
            <v-btn
              icon
              @click="editDialog.active = false"
              :disabled="editDialog.loading"
            >
              <v-icon color="grey darken-3"> mdi-close </v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-form ref="form" @submit.prevent="updateConsent()">
          <v-card-text class="pt-0">
            <ConsentForm
              v-model="editDialog.consent"
              :disabled="editDialog.loading"
              hideTitle
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              :disabled="editDialog.loading"
              @click="editDialog.active = false"
            >
              Cancelar
            </v-btn>
            <v-btn color="success" type="submit" :loading="editDialog.loading">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ConsentForm from "@/components/ConsentForm.vue";
import ConsentDataPanel from "@/components/ConsentDataPanel.vue";
import ConsentRequestContent from "@/components/ConsentRequestContent.vue";

export default {
  name: "Consent",

  components: { ConsentForm, ConsentDataPanel, ConsentRequestContent, },

  data: () => ({
    editDialog: {
      active: false,
      consent: {},
      loading: false,
    },
    loading: false,
    consent: {},
    showingOld: false,
    textToSend: "Instale o aplicativo eConsenso (https://econsenso.com.br/aplicativo) e "
                + "cadastre-se com seu CPF para responder ao consentimento que solicitei. "
                + "Você também pode responder por um link que recebeu por e-mail.",
  }),
  computed: {
    bearer() {
      return (this.consent.bearer ? this.consent.bearer : this.consent.new_bearer) || {};
    },
    withNewBearer(){
      // if (this.loading) return false
      return (this.consent.bearer == null && this.consent.new_bearer != null)
    },
    currentAnswer(){
      if (this.consent.consent_requests == undefined){
        return undefined;
      }
      return this.consent.consent_requests[0].answers[0];
    },
  },
  methods: {
    updateConsent() {
      if (!this.$refs.form.validate()) {
        this.$showMessage('warning', "Preencha os campos corretamente");
      } else {
        this.editDialog.loading = true;
        this.$axios
          .put(`/consent/${this.consent.id}`, this.editDialog.consent)
          .then(() => {
            this.editDialog.active = false;
            this.$showMessage(
              'success',
              "Nova solicitação de Consentimento criada"
            );
            this.loadConsent();
          })
          .catch(() => {
            this.$showMessage('error', "Ocorreu um erro inesperado");
          })
          .finally(() => {
            this.editDialog.loading = false;
          });
      }
    },
    openEditDialog() {
      this.editDialog.active = true;
      this.editDialog.consent = JSON.parse(
        JSON.stringify(this.consent.consent_requests[0])
      );
    },
    copyText() {
      this.$refs.textToCopy.$el.querySelector('textarea').select();      
      document.execCommand("copy");
      this.$showMessage('grey darken-2', "Texto Copiado");
    },
    loadConsent() {
      this.loading = true;
      this.$axios
        .get(`/consent/${this.$route.params.consent_id}`)
        .then((response) => {
          this.consent = response.data;
        })
        .catch((error) => {
          this.$showMessage(
            "error",
            "Não foi possivel carregar os dados do consentimento"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.loadConsent();
  },
};
</script>
